import { createApi } from '@reduxjs/toolkit/query/react';
import { memberApi } from '@/lib/member/memberAPI';
import {
  CreateProjectData,
  CreateProjectProps,
  PinProjectProps,
  ProjectDetail,
  ProjectDetailResponse,
  SelfProjects,
  SelfProjectsByFilter,
  SelfProjectsByFilterResponse,
  SelfProjectsResponse,
  UpdateProjectDetailRequestParameters,
  QueryProjectCheckInStatusProjectProps,
  QueryMemberProjectsByPageResponse,
  QueryMemberProjectsByPageRequest,
  QueryProjectByFilterRequest,
  RemoveUserFromProjectsRequest,
  QuerySelfProjectByFilter,
  QuerySelfProjectByFilterRequest,
} from './Interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const projectsApi = createApi({
  reducerPath: 'projectsApi',
  tagTypes: ['Project', 'MemberProjects', 'ProjectsList'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSelfProjects: builder.query<SelfProjects[], number>({
      query: (groupId) => ({
        url: `/api/project/GetSelfProjects`,
        method: 'GET',
        params: { GroupId: groupId },
      }),
      transformResponse: (response: SelfProjectsResponse) => response.data.data,
      forceRefetch: () => true, // 實驗更新狀況
      providesTags: ['ProjectsList'],
    }),
    QueryProjectByFilter: builder.query<
      SelfProjectsByFilter[],
      QueryProjectByFilterRequest
    >({
      query: (params) => ({
        url: `/api/Project/QueryProjectByFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response: SelfProjectsByFilterResponse) =>
        response.data.data,
    }),
    getProjectDetail: builder.query<ProjectDetail, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectDetail`,
        method: 'GET',
        params: { projectId },
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
    }),
    CreateProject: builder.mutation<CreateProjectData, CreateProjectProps>({
      query: (data) => ({
        url: `/api/Project/CreateProject`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    UpdateProjectDetail: builder.mutation<
      UpdateProjectDetailRequestParameters,
      any
    >({
      query: (data) => ({
        url: `/api/Project/UpdateProjectDetail`,
        method: 'PUT',
        data,
      }),
      transformResponse: (response: ProjectDetailResponse) =>
        response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    PinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/PinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    UnPinProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/UnPinProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    LeaveProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/Leave`,
        method: 'POST',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    CheckProjectAttendanceToday: builder.query<any, number>({
      query: (projectId) => ({
        url: `/api/project/CheckProjectAttendanceToday`,
        method: 'GET',
        params: { projectId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    ArchiveProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/ArchiveProject`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    DeleteProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/DeleteProject`,
        method: 'DELETE',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['ProjectsList'],
    }),
    AddUserToProject: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId, userIds = [] }) => ({
        url: `/api/Project/AddUserToProject`,
        method: 'POST',
        data: { projectId, userId, roleId, userIds },
      }),
      transformResponse: (response) => response.data.data,
    }),
    ModifyUserRole: builder.mutation<any, PinProjectProps>({
      query: ({ projectId, userId, roleId }) => ({
        url: `/api/Project/ModifyUserRole`,
        method: 'PUT',
        data: { projectId, userId, roleId },
      }),
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['MemberProjects'],
    }),
    RemoveUser: builder.mutation<any, RemoveUserFromProjectsRequest>({
      query: (data) => ({
        url: `/api/Project/RemoveUser`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(memberApi.util.invalidateTags(['members']));
        // dispatch(projectsApi.util.invalidateTags(['MemberProjects']));
      },
      transformResponse: (response) => response.data.data,
      invalidatesTags: ['MemberProjects'],
    }),
    GetProjectCurrentStatusSummary: builder.query<any, number>({
      query: (projectId) => ({
        url: `/api/Project/GetProjectCurrentStatusSummary`,
        method: 'GET',
        params: { ProjectId: projectId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    GetActivityViewModelsByProjectWithPaged: builder.query<any, any>({
      query: ({ projectId, pageCount, pageNumber, createdBy }) => ({
        url: `/api/Project/GetActivityViewModelsByProjectWithPaged`,
        method: 'GET',
        params: { projectId, pageCount, pageNumber, createdBy },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: () => true,
    }),
    QueryProjectCheckInStatus: builder.mutation<
      any,
      QueryProjectCheckInStatusProjectProps
    >({
      query: ({ projectId, projectIds, userId, roleId, userIds }) => ({
        url: `/api/Project/QueryProjectCheckInStatus`,
        method: 'POST',
        data: { projectId, projectIds, userId, roleId, userIds },
      }),
      transformResponse: (response) => response.data.data,
    }),
    QueryMemberProjectsByPage: builder.query<
      QueryMemberProjectsByPageResponse,
      QueryMemberProjectsByPageRequest
    >({
      query: (params) => ({
        url: `/api/Project/QueryMemberProjectsByPage`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        return `${endpointName}-${queryArgs.groupId}-${queryArgs.userId ?? queryArgs.inviteEmail}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.pageNumber !== previousArg?.pageNumber ||
          currentArg?.userId !== previousArg?.userId ||
          currentArg?.groupId !== previousArg?.groupId ||
          currentArg?.inviteEmail !== previousArg?.inviteEmail
        );
      },
      // merge: (currentCache, newItems, { arg }) => {
      //   // 如果是第一頁，直接返回新數據
      //   if (arg.pageNumber === 1) {
      //     return newItems;
      //   }
      //   // 合併數據
      //   return {
      //     ...newItems,
      //     projects: {
      //       ...newItems.projects,
      //       data: [...currentCache.projects.data, ...newItems.projects.data],
      //     },
      //   };
      // },
      transformResponse: (response) => response.data.data,
      providesTags: ['MemberProjects'],
    }),
    querySelfProjectByFilter: builder.query<
      QuerySelfProjectByFilter[],
      QuerySelfProjectByFilterRequest
    >({
      query: (params) => ({
        url: `/api/Project/QuerySelfProjectByFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetSelfProjectsQuery,
  useQueryProjectByFilterQuery,
  useGetProjectDetailQuery,
  useCreateProjectMutation,
  useUpdateProjectDetailMutation,
  usePinProjectMutation,
  useUnPinProjectMutation,
  useLeaveProjectMutation,
  useCheckProjectAttendanceTodayQuery,
  useArchiveProjectMutation,
  useDeleteProjectMutation,
  useAddUserToProjectMutation,
  useModifyUserRoleMutation,
  useRemoveUserMutation,
  // track
  useGetProjectCurrentStatusSummaryQuery,
  useQueryProjectCheckInStatusMutation,
  useGetActivityViewModelsByProjectWithPagedQuery,
  useQueryMemberProjectsByPageQuery,
  useQuerySelfProjectByFilterQuery,
} = projectsApi;
